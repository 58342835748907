<template>
    <modal
        class="get-started-modal get-started-invited"
        :adaptive="true"
        name="get-started-invited"
        height="auto"
        width="900"
        scrollable
    >
        <div class="close-modal" @click="$modal.hide('get-started-invited')">
            <img src="@assets/img/icons/close.svg" alt="X">
        </div>
        <div class="row no-gutters">
            <div class="col-12 col-lg-6 copy">
                <h1>Hello {{ invitedFounder.firstname }}!</h1>
                <p>Thanks for your interest in joining the Founding Creator Program. We’re glad you’re here.</p>
                <p>For the smoothest onboarding, let’s set up your account now. (Note: You can also set it up after downloading, if you prefer.)</p>
            </div>
            <div class="col-12 col-lg-6">
                <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
                    <form novalidate @submit.prevent="handleSubmit(submitFounderRequest)">
                        <div class="body">
                            <div class="form-group">
                                <label>Email Address:</label>
                                <input
                                    :value="invitedFounder.email"
                                    type="text"
                                    class="form-control"
                                    disabled
                                >
                            </div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="lazy"
                                name="password"
                                rules="required|password"
                            >
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="password">Password:</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <input
                                        id="password"
                                        v-model="password"
                                        type="password"
                                        class="form-control"
                                        placeholder="Enter your password"
                                    >
                                </div>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="confirm password"
                                rules="required|passwordConfirm:@password"
                            >
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="confirmPassword">Confirm Password:</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <input
                                        id="confirmPassword"
                                        v-model="verify_password"
                                        type="password"
                                        class="form-control"
                                        placeholder="Enter your confirm password"
                                    >
                                </div>
                            </ValidationProvider>
                            <div class="newsletter form-check">
                                <input
                                    id="newsletter"
                                    v-model="newsletter"
                                    class="form-check-input"
                                    type="checkbox"
                                >
                                <label class="form-check-label" for="newsletter">
                                    <div class="box">
                                        <transition name="fade">
                                            <img v-if="newsletter" src="@assets/img/icons/check.svg" alt="Check">
                                        </transition>
                                    </div>
                                    I would like to receive occasional email updates about the Founding Creator Program
                                </label>
                            </div>
                        </div>
                        <div class="footer">
                            <button :disabled="!valid" class="btn btn-secondary">
                                Get Early Access
                            </button>
                            <p class="disclaimer">
                                by tapping on Get Early Access you are agreeing<br>
                                <a href="/founders/program-terms-of-service" target="_blank">"Terms and Conditions"</a> and <a href="/legal/privacy-policy" target="_blank">"Privacy Policies"</a>
                            </p>
                        </div>
                    </form>
                </validationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { extend } from "vee-validate";
import { setTokens } from "@/utils";

extend("passwordConfirm", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password confirmation does not match"
});

extend("password", {
    params: ["target"],
    validate: value => {
        return value.length >= 8
    },
    message: "Password must be at least 8 characters long"
});

export default {
    name: "GetStartedInvited",
    props: {
        invitedFounder: {
            type: Object,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            password: "",
            verify_password: "",
            newsletter: true,
            list_id: "425b3c371a"
        };
    },
    methods: {
        submitFounderRequest() {
            const data = { ...this.$data, email: this.invitedFounder.email, token: this.token }
            axiosPublic.post(`/invitations-web/register`, data).then(({ data }) => {
                this.$modal.hide("get-started-invited");
                this.$store.dispatch("User/setToken", data.session.token);

                setTokens(data.session);
     
                this.$store.dispatch("Application/getGlobalStateData").then(() => {
                    this.$router.push({ name: "web-app" });
                });
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        }
    }
}
</script>
